import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeCheckOutElementForm } from './stripe-checkout-form';
import { TCheckoutProduct } from '@monetization/service';

interface Props {
  stripe_account_id?: string;
  client_secret: string;
  product: TCheckoutProduct;
  platform_flat_fee: number;
  onClose: (reason: 'cancel' | 'completion') => void;
  onRefetchSubscriptions: VoidFunction;
  onPaymentSuccess: VoidFunction;
}
export const StripeCheckoutElement = ({
  client_secret,
  product,
  stripe_account_id,
  platform_flat_fee,
  onRefetchSubscriptions,
  onClose,
  onPaymentSuccess,
}: Props) => {
  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY, {
    stripeAccount: stripe_account_id,
  });

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: client_secret, appearance: { theme: 'flat' } }}>
      <StripeCheckOutElementForm
        product={product}
        onClose={onClose}
        onRefetchSubscriptions={onRefetchSubscriptions}
        platform_flat_fee={platform_flat_fee}
        onPaymentSuccess={onPaymentSuccess}
      />
    </Elements>
  );
};
