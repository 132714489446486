import { useCancelUserSubscriptionMutation } from '@lib/redux/api/monetization-api';
import { IUserSubscription } from '@monetization/service';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  useToast,
} from '@ui/components';
import { useLoader } from '@ui/contexts';
import React, { useState } from 'react';

interface Props {
  userSubscription: IUserSubscription;
  onClose: (reason: 'cancel' | 'completion') => void;
}
export const SubscriptionCancellationPrompt = ({ userSubscription, onClose }: Props) => {
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);
  const toast = useToast();
  const [cancelSubscription, { isLoading }] = useCancelUserSubscriptionMutation();
  const { closeLoader, openLoader } = useLoader();

  const handleCancelSubscription = async () => {
    if (!userSubscription?._id) return;
    openLoader();
    try {
      await cancelSubscription({
        user_id: userSubscription.user_id,
        user_subscription_id: userSubscription._id,
      }).unwrap();
      closeLoader();
      setShowCancelPrompt(false);
      onClose('cancel');
    } catch (error) {
      toast(
        {
          title: 'Oops! Something went wrong',
          message: error?.data?.message || error?.data?.error,
        },
        { type: 'error' },
      );
      closeLoader();
    }
  };

  return (
    <Dialog open={showCancelPrompt} onOpenChange={setShowCancelPrompt}>
      <DialogTrigger className="w-full">
        <Button
          type="outline"
          size="small"
          className="w-full border border-primary bg-primaryLight/30 text-primary"
          disabled={isLoading}>
          {isLoading ? 'Cancelling...' : 'Cancel Membership'}
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px] bg-white">
        <DialogHeader>
          <DialogTitle>Cancel Membership</DialogTitle>
          <DialogDescription>
            Action cannot be undone. You will lose all benefits & perks associated to this
            membership plan.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            type="primary"
            size="small"
            onClick={handleCancelSubscription}
            disabled={isLoading}>
            {isLoading ? 'Cancelling...' : 'Confirm Cancellation'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
