import { ICreatorProduct } from '@monetization/service';
import React, { useMemo } from 'react';
import { Crown, Sparkle, Gem } from 'lucide-react';

export const tierOrderOptions: Record<number, { icon: JSX.Element; iconColor: string }> = {
  6: {
    icon: <Crown className="text-[#343840]" />,
    iconColor: '#3B8',
  },
  5: {
    icon: <Gem className="text-[#343840]" />,
    iconColor: '#3B8',
  },
  4: {
    icon: <Sparkle className="text-[#343840]" />,
    iconColor: '#10B981',
  },
  3: {
    icon: <Sparkle className="text-[#343840]" />,
    iconColor: '#10B981',
  },
  2: {
    icon: <Sparkle className="text-[#343840]" />,
    iconColor: '#10B981',
  },
  1: {
    icon: <Sparkle className="text-[#343840]" />,
    iconColor: '#10B981',
  },
};

interface Props {
  product: ICreatorProduct;
  tierOrder: number;
}
export const SubscriptionProductIcon = ({ product, tierOrder }: Props) => {
  // const tierOrderOption = useMemo(() => tierOrderOptions[tierOrder], [tierOrder]);

  return (
    <div
      className="h-10 w-10 p-2 rounded-full flex justify-center items-center"
      style={{ backgroundColor: '#3B8' }}>
      <Gem className="text-[#343840] w-10 h-10" size={8} />
    </div>
  );
};
