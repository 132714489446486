import React, { useState } from 'react';
import { Check } from 'lucide-react';

interface Props {
  perks: string[];
  maxVisible?: number;
}
const PerksList = ({ perks, maxVisible }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const handleToggle = () => setShowAll(!showAll);
  const visiblePerks = showAll || !maxVisible ? perks : perks.slice(0, maxVisible);

  return (
    <div className="space-y-3">
      {visiblePerks.map((p, index) => (
        <div key={index} className="flex items-center gap-2">
          <div className="flex justify-center items-center h-4 w-4 rounded-full border border-primary">
            <Check className="text-primary h-3 w-3" />
          </div>
          <p className="font-sm font-normal">{p}</p>
        </div>
      ))}

      {maxVisible && perks.length > maxVisible && (
        <button className="text-primary font-medium mt-3 underline" onClick={handleToggle}>
          {showAll ? 'Show less' : 'See all benefits'}
        </button>
      )}
    </div>
  );
};

export default PerksList;
