import React from 'react';
import { Check } from 'lucide-react';
import { Button, Separator } from '@ui/components';
import { useClickOutside } from '@ui/hooks';

interface Props {
  onClose: (reason: 'cancel' | 'completion') => void;
  product_name: string;
  price: number;
  platform_flat_fee: number;
  total: number;
}
export const StripePaymentSuccess = (props: Props) => {
  const ref = useClickOutside(() => props.onClose('completion'));

  return (
    <div className="space-y-4 flex flex-col justify-center items-center p-6" ref={ref}>
      <div className="rounded-full h-16 w-16 bg-green-100 flex justify-center items-center">
        <div className="rounded-full h-8 w-8 bg-green-900 flex justify-center items-center">
          <Check className="text-white" />
        </div>
      </div>
      <div>
        <p className="text-center font-normal text-xl">Payment Success!</p>
        <p className="text-center text-xs">Your payment was processed successfully.</p>
      </div>
      <div className="w-full space-y-4 bg-bgGrey rounded-md p-4">
        <div className="flex justify-between">
          <p className="text-md">Amount</p>
          <p className="text-md font-semibold">${props.price}</p>
        </div>
        <Separator />
        <div className="flex justify-between">
          <p className="text-md">Processing Fee</p>
          <p className="text-md font-semibold">${props.platform_flat_fee}</p>
        </div>
        <Separator />
        <div className="flex justify-between">
          <p className="text-md">Total</p>
          <p className="text-md font-semibold">${props.total}</p>
        </div>
      </div>
      <p className="text-center text-xs">A receipt has been sent to your email.</p>

      <Button size="small" type="primary" onClick={() => props.onClose('completion')}>
        Continue
      </Button>
    </div>
  );
};
