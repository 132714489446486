import { IActiveSubscription, ICreatorProduct, IUserSubscription } from '@monetization/service';
import React, { useCallback, useMemo, useState } from 'react';
import { SubscriptionProductIcon, tierOrderOptions } from './subscription-product-icon';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  MobileHeaderBackButton,
  Separator,
  useToast,
} from '@ui/components';
import { TextWithLinks } from '@ui/components/moleculas/message-link';
import {
  useCancelUserSubscriptionMutation,
  useCreateUserSubscriptionMutation,
  useDowngradeUserSubscriptionMutation,
  useUpgradeUserSubscriptionMutation,
} from '@lib/redux/api/monetization-api';
import { useLoader } from '@ui/contexts';
import { StripeCheckoutElement } from './stripe-checkout-element';
import { useAppSelector } from '@lib/redux/store';
import { SubscriptionCancellationPrompt } from './subscription-cancellation-prompt';
import dayjs from 'dayjs';
import { AlertCircle, Check } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { ROUTES } from '@constants/routes.constants';
import { useMobile } from '@ui/hooks';
import { twMerge } from 'tailwind-merge';
import PerksList from './perk-list';

interface Props {
  product: ICreatorProduct | null;
  userSubscription?: IUserSubscription | IActiveSubscription;
  userSubscriptionForComputation?: IUserSubscription;
  canShowManageSubscriptionBtn: boolean;
  canShowCancelSubscriptionBtn: boolean;
  overviewClassName?: string;
  forceShowBackBtn?: boolean;
  shouldUseCancelPlan?: boolean;
  enableUpgradeOrDowngradePlan?: boolean;
  onRefetchSubscriptions: VoidFunction;
  onBack?: () => void;
  onClose: (reason: 'cancel' | 'completion') => void;
  onPaymentSuccess: VoidFunction;
}
export const SubscriptionProductSelected = (props: Props) => {
  const isMobile = useMobile();
  const router = useRouter();
  const toast = useToast();
  const user = useAppSelector((state) => state.user.user);
  const { closeLoader, openLoader } = useLoader();
  const [
    createUserSubscription,
    { data: createUserSubscriptionData, isLoading: isCreateUserSubscriptionLoading },
  ] = useCreateUserSubscriptionMutation();
  const [
    upgradeUserSubscription,
    { data: upgradeUserSubscriptionData, isLoading: isUpgradeUserSubscriptionLoading },
  ] = useUpgradeUserSubscriptionMutation();
  const [
    downgradeUserSubscription,
    { data: downgradeUserSubscriptionData, isLoading: isDowngradeUserSubscriptionLoading },
  ] = useDowngradeUserSubscriptionMutation();
  const [showCheckout, setShowCheckout] = useState(false);

  const [cancelUserSubscription, { isLoading: isLoadingCancel, data: cancelUserSubscriptionData }] =
    useCancelUserSubscriptionMutation();

  const baseColor = useMemo(
    () => tierOrderOptions[props.product.tier_order || 1].iconColor,
    [props.product.tier_order],
  );
  const hasActiveSubscription = useMemo(
    () => props.userSubscription?.status === 'active',
    [props.userSubscription?.status],
  );
  const hasCancelledPendingSubscription = useMemo(
    () => props.userSubscription?.status === 'cancelled_pending',
    [props.userSubscription?.status],
  );
  const hasActiveOrCancelledPendingSubscription = useMemo(
    () => hasActiveSubscription || hasCancelledPendingSubscription,
    [props.userSubscription?.status, hasActiveSubscription],
  );
  const cancelAt = useMemo(() => {
    if (props.userSubscription?.cancel_at) {
      return dayjs(props.userSubscription?.cancel_at * 1000).format('MMMM D, YYYY');
    }
    return null;
  }, [props.userSubscription?.cancel_at]);

  const gotoManageSubscriptions = () =>
    router.push(
      `${isMobile ? ROUTES.settingsMobile : ROUTES.settings}?tab=subscriptions&redirect_url=${
        window.location.href
      }`,
    );

  const canShowBackBtn = useMemo(
    () => props.forceShowBackBtn || !hasActiveOrCancelledPendingSubscription,
    [hasActiveOrCancelledPendingSubscription, props.forceShowBackBtn],
  );

  const continueOrUpgradeOrDowngradeTextSubscriptionText = useMemo(() => {
    if (
      props.enableUpgradeOrDowngradePlan &&
      props.userSubscriptionForComputation?.creator_product?.price
    ) {
      if (props.product.price > props.userSubscriptionForComputation?.creator_product?.price) {
        return 'Upgrade plan';
      }
      if (props.product.price < props.userSubscriptionForComputation?.creator_product?.price) {
        return 'Downgrade plan';
      }
      if (
        props.product._id === props.userSubscriptionForComputation?.creator_product?._id &&
        props.shouldUseCancelPlan
      ) {
        return 'Cancel plan';
      }
    }

    if (props.userSubscription || props.userSubscriptionForComputation) {
      return 'Manage subscriptions';
    }

    return 'Continue';
  }, [
    props.userSubscriptionForComputation?.creator_product?.price,
    props.enableUpgradeOrDowngradePlan,
  ]);

  const mutationData = useMemo(
    () =>
      createUserSubscriptionData || upgradeUserSubscriptionData || downgradeUserSubscriptionData,
    [createUserSubscriptionData, upgradeUserSubscriptionData, downgradeUserSubscriptionData],
  );

  const onMutateSubscription = useCallback(async () => {
    if (continueOrUpgradeOrDowngradeTextSubscriptionText === 'Manage subscriptions') {
      return gotoManageSubscriptions();
    }

    try {
      openLoader();

      switch (continueOrUpgradeOrDowngradeTextSubscriptionText) {
        case 'Upgrade plan':
          const upgradeRes = await upgradeUserSubscription({
            creator_product_id: props.product._id,
            user_id: user.id,
          }).unwrap();
          if (upgradeRes?.upgraded) {
            toast(
              {
                title: 'Success',
                message:
                  'You have successfully upgraded your plan. The prorated amount will be charged to your card on the next billing cycle.',
              },
              { type: 'success', autoClose: 10000 },
            );
            props.onClose('completion');
          }
          break;
        case 'Downgrade plan':
          await downgradeUserSubscription({
            creator_product_id: props.product._id,
            user_id: user.id,
          }).unwrap();
          toast(
            {
              title: 'Success',
              message: 'You have successfully downgraded your plan',
            },
            { type: 'success', autoClose: 10000 },
          );
          props.onClose('completion');
          break;
        case 'Cancel plan':
          await cancelUserSubscription({
            user_subscription_id: props.userSubscriptionForComputation?._id,
            user_id: user.id,
          }).unwrap();
          toast(
            {
              title: 'Success',
              message: 'You have successfully canceled your plan',
            },
            { type: 'success', autoClose: 10000 },
          );
          props.onClose('completion');
          break;
        case 'Continue':
          const creationRes = await createUserSubscription({
            creator_product_id: props.product._id,
            user_id: user.id,
          }).unwrap();
          if (creationRes?.paid) {
            toast(
              {
                title: 'Success',
                message: 'You have successfully subscribed to this plan.',
              },
              { type: 'success', autoClose: 10000 },
            );
            setTimeout(() => {
              props.onClose('completion');
            }, 1000);
            props.onClose('completion');
          }
          break;
      }

      closeLoader();
      setShowCheckout(true);
    } catch (error) {
      closeLoader();
      toast(
        {
          title: 'Oops! Something went wrong',
          message: error?.data?.message || error?.data?.error,
        },
        { type: 'error' },
      );
    }
  }, [continueOrUpgradeOrDowngradeTextSubscriptionText]);

  const perks = useMemo(
    () => [
      ...(props.product?.perks || []),
      ...(props.product?.inherited_products?.map((product) => product?.perks || []).flat() || []),
    ],
    [props.product?.inherited_products, props.product?.perks],
  );

  return (
    <>
      {!showCheckout && (
        <div
          className={twMerge(
            'bg-white rounded-[26px] space-y-6 p-4 relative h-full',
            props.overviewClassName,
          )}>
          {canShowBackBtn && (
            <div className="flex items-center gap-4 relative">
              <div className="z-40">
                <MobileHeaderBackButton onBack={props.onBack} />
              </div>
              <p className="font-semibold text-sm text-center w-full absolute z-0">Plan Overview</p>
            </div>
          )}

          <div className="max-h-[70vh] space-y-6 overflow-y-auto scrollbar-none">
            <div className="bg-[#343840] space-y-4 rounded-[26px] p-4">
              <div className="flex justify-between items-center gap-2">
                <SubscriptionProductIcon
                  product={props.product}
                  tierOrder={props.product.tier_order || 1}
                />
                <p className="text-white">
                  <span className="font-bold text-lg">${props.product.price}</span>
                  <span className="text-sm">/ month</span>
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-white font-semibold text-2xl capitalize">
                  {props.product.product_name}
                </p>
                {hasActiveOrCancelledPendingSubscription && (
                  <div className="flex flex-col justify-self-end items-end gap-1">
                    <div
                      className={`border px-2 py-1 rounded-full text-xs whitespace-nowrap w-fit`}
                      style={{ color: baseColor, borderColor: baseColor }}>
                      Active Plan
                    </div>
                    {hasCancelledPendingSubscription && cancelAt && (
                      <p
                        className="text-xs text-primary"
                        style={{ fontSize: '11px', textAlign: 'end' }}>
                        Cancels {cancelAt}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>

            {!hasCancelledPendingSubscription && (
              <>
                <p className="font-light text-sm">
                  You&apos;ll be charged ${props.product.price} monthly. Manage or cancel your
                  subscription anytime.
                </p>

                <Separator />
              </>
            )}

            <p className="text-sm space-y-4">
              <TextWithLinks
                text={props.product.description}
                useCase="messaging"
                alwaysShowFullText
                className="font-extralight"
              />

              {perks.length > 0 && <PerksList perks={perks} />}
            </p>
          </div>

          <div className="bottom-0 w-full space-y-4">
            {hasCancelledPendingSubscription && cancelAt && (
              <Alert variant="default" className="rounded border-primary text-primary">
                <AlertCircle className="h-4 w-4 !text-primary" />
                <AlertTitle>Membership Cancelled</AlertTitle>
                <AlertDescription className="text-primaryActive">
                  You will no longer be billed for the subscription. Your membership will remain
                  valid until {cancelAt}
                </AlertDescription>
              </Alert>
            )}

            {hasCancelledPendingSubscription && !cancelAt && (
              <Alert variant="default" className="rounded border-primary text-primary">
                <AlertCircle className="h-4 w-4 !text-primary" />
                <AlertTitle>Membership Cancellation in Progress</AlertTitle>
                <AlertDescription className="text-primaryActive">
                  Your request is being processed. This might take up to 10 seconds. Please refresh
                  the page afterward to see the updated status.
                </AlertDescription>
              </Alert>
            )}

            {props.canShowManageSubscriptionBtn && hasActiveOrCancelledPendingSubscription && (
              <Button
                onClick={gotoManageSubscriptions}
                type="primary"
                size="small"
                className="w-full">
                Manage Subscriptions
              </Button>
            )}

            {!hasActiveOrCancelledPendingSubscription && (
              <Button
                isLoading={
                  isCreateUserSubscriptionLoading ||
                  isUpgradeUserSubscriptionLoading ||
                  isDowngradeUserSubscriptionLoading
                }
                disabled={
                  props.userSubscriptionForComputation?.status === 'cancelled_pending' &&
                  props.product._id === props.userSubscriptionForComputation?.creator_product?._id
                }
                onClick={onMutateSubscription}
                type="primary"
                size="small"
                className="w-full">
                {continueOrUpgradeOrDowngradeTextSubscriptionText}
              </Button>
            )}
            {hasActiveSubscription && props.canShowCancelSubscriptionBtn && (
              <SubscriptionCancellationPrompt
                userSubscription={props.userSubscription}
                onClose={props.onClose}
              />
            )}
          </div>
        </div>
      )}

      {showCheckout && mutationData?.client_secret && (
        <StripeCheckoutElement
          onRefetchSubscriptions={props.onRefetchSubscriptions}
          stripe_account_id={mutationData.creator_stripe_account_id}
          client_secret={mutationData.client_secret}
          product={props.product}
          onClose={props.onClose}
          platform_flat_fee={mutationData.platform_flat_fee}
          onPaymentSuccess={props.onPaymentSuccess}
        />
      )}
    </>
  );
};
