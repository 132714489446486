import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useLoader } from '@ui/contexts';
import React, { useEffect, useState } from 'react';
import { StripePaymentSuccess } from './stripe-payment-success';
import { Button, Separator, useToast } from '@ui/components';
import { TCheckoutProduct } from '@monetization/service';

interface Props {
  product: TCheckoutProduct;
  platform_flat_fee: number;
  onRefetchSubscriptions: VoidFunction;
  onClose: (reason: 'cancel' | 'completion') => void;
  onPaymentSuccess: VoidFunction;
}
export const StripeCheckOutElementForm = ({
  onPaymentSuccess,
  product,
  platform_flat_fee,
  onClose,
}: Props) => {
  const toast = useToast();
  const { closeLoader, openLoader } = useLoader();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentComplete, setPaymentComplete] = useState(false);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      openLoader();
      if (!stripe || !elements) {
        return;
      }
      closeLoader();
    };

    checkPaymentStatus();
  }, [stripe, elements]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    openLoader();
    const result = await stripe.confirmPayment({ elements, redirect: 'if_required' });

    if (result.error) {
      closeLoader();
      console.error(result.error.message);
      toast(
        {
          title: 'Payment Failed',
          message: result.error.message,
        },
        {
          type: 'error',
        },
      );
    } else {
      setPaymentComplete(true);
      closeLoader();
      onPaymentSuccess();
    }
  };

  if (paymentComplete) {
    return (
      <StripePaymentSuccess
        onClose={onClose}
        product_name={product.product_name}
        price={product.price}
        platform_flat_fee={platform_flat_fee}
        total={product.price + platform_flat_fee}
      />
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="block space-y-4 h-full relative p-4 pb-24 overflow-y-auto max-h-[99vh] scrollbar-none">
      <p className="text-lg font-semibold">Complete Payment</p>
      <PaymentElement options={{ layout: { type: 'tabs', defaultCollapsed: false } }} />
      <div className="flex justify-between">
        <p className="text-md font-semibold">{product.product_name}</p>
        <p className="text-md font-semibold">${product.price}</p>
      </div>
      <Separator />
      <div className="flex justify-between">
        <p className="text-md font-semibold">Processing Fee</p>
        <p className="text-md font-semibold">${platform_flat_fee}</p>
      </div>
      <Separator />
      <div className="flex justify-between">
        <p className="text-md font-semibold">Total</p>
        <p className="text-md font-semibold">${product.price + platform_flat_fee}</p>
      </div>
      <Separator />
      <div className="fixed bottom-0 left-0 right-0 p-4">
        <Button type="primary" size="small" className="w-full" disabled={!stripe || !elements}>
          Make Payment
        </Button>
      </div>
    </form>
  );
};
